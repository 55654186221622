/* istanbul ignore file */

// Polyfills
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

// Main
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from 'components'

ReactDOM.render(<App />, document.getElementById('root'))
