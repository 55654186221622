import React from 'react'
import style from './OutputText.module.css'
import { Package } from 'types'

type ElementOrString = JSX.Element | string

const formatPackage = (pkg: Package, index: number): ElementOrString => {
  if (!pkg.match) {
    return pkg.query
  }
  return (
    <a key={`link_${index}`} href={pkg.link} title={pkg.name}>
      {pkg.query}
    </a>
  )
}

interface OutputTextProps {
  packages: Package[]
  percentage: number
}

const OutputText = ({ packages, percentage }: OutputTextProps) => (
  <>
    <p className={style.OutputText}>
      {packages
        .map((pkg, index) => formatPackage(pkg, index))
        .reduce<Array<ElementOrString>>(
          (prev, curr) => [...prev, ' ', curr],
          []
        )}
    </p>
    <p className={style.OutputText_percentage}>{percentage}%</p>
    {percentage === 0 && <p>¯\_(ツ)_/¯</p>}
  </>
)

export default OutputText
