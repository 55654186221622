import React, { useEffect, useState } from 'react'
import style from './Loader.module.css'
import { choose } from 'utils'

import {
  partywizardData,
  parrotData,
  congaparrotData,
  nyanparrotData,
} from './imgs'

interface GifWithRepeat {
  gif: string
  repeat: number
}

interface LoadingGifs {
  [index: string]: number
}

const GIFS: LoadingGifs = {
  [partywizardData]: 1,
  [parrotData]: 1,
  [congaparrotData]: 3,
  [nyanparrotData]: 1,
}

const randomGif = (gifs: LoadingGifs): GifWithRepeat => {
  const gifName = choose(Object.keys(gifs))
  const repeat = gifs[gifName]
  return { gif: gifName, repeat }
}

const Loader = ({ ...props }) => {
  const [images, setImages] = useState<string[]>([])

  useEffect(() => {
    const { gif, repeat } = randomGif(GIFS)
    setImages(Array(repeat).fill(gif))
  }, [])

  return (
    <div {...props} className={style.Loader}>
      {images.map((img, index) => (
        <img key={`loader_${index}`} src={img} alt="Loading..." />
      ))}
    </div>
  )
}

export default Loader
