import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { Package } from 'types'
import { getWords, percent } from 'utils'

const SEARCH_BASE_URL = 'https://registry.npmjs.com/-/v1/search?text='
const PKG_BASE_URL = 'https://registry.npmjs.com/'
const NPM_BASE_LINK = 'https://www.npmjs.com/package/'

interface UseNpmApiHookData {
  packages: Package[]
  isError: boolean
  isLoading: boolean
  percentage: number
}

interface RequestObj {
  abortController: AbortController
  request: Promise<PackageJson>
  query: string
}

interface NpmRegistryJsonResponse {
  objects?:
    | [
        {
          package: { links: { npm: string }; name: string }
          searchScore: number
        }
      ]
    | []
}

interface PackageJson {
  json: NpmRegistryJsonResponse
  query: string
}

type UseNpmApiHookReturn = [
  UseNpmApiHookData,
  string,
  Dispatch<SetStateAction<string>>
]

const formatPackageJsonResponse = (
  packageName: string
): NpmRegistryJsonResponse => {
  return {
    objects: [
      {
        package: {
          links: { npm: `${NPM_BASE_LINK}${packageName}` },
          name: packageName,
        },
        searchScore: 1,
      },
    ],
  }
}

const jsonFetch = async (url: string, signal: AbortSignal, cors = true) => {
  const response = await fetch(url, {
    mode: cors ? 'cors' : 'no-cors',
    signal,
  })

  if (!response.ok) {
    return { error: 'Not found' }
  }

  if (!cors) {
    return {}
  }

  return await response.json()
}

const fetchQuery = async (query: string, signal: AbortSignal) => {
  const pkgJsonFetch = jsonFetch(`${PKG_BASE_URL}${query}`, signal, false)
  const searchJsonFetch = jsonFetch(`${SEARCH_BASE_URL}${query}`, signal)

  const [pkgJsonResponse, searchJsonResponse] = await Promise.all([
    pkgJsonFetch,
    searchJsonFetch,
  ])

  const json =
    typeof pkgJsonResponse.error === 'undefined'
      ? formatPackageJsonResponse(query)
      : searchJsonResponse

  return {
    json,
    query,
  }
}

const getPkgDetails = (packageResponse: PackageJson) => {
  const { json, query } = packageResponse

  const noMatch = { match: false, query }

  if (!json.objects || json.objects.length === 0) {
    return noMatch
  }

  const obj = json.objects[0]
  const pkg = obj.package
  const searchScore = obj.searchScore

  if (searchScore < 1) {
    return noMatch
  }

  return {
    link: pkg.links.npm,
    match: true,
    name: pkg.name,
    query,
    searchScore,
  }
}

const makeAllRequests = (requestObjs: RequestObj[]) =>
  Promise.all(requestObjs.map(requestObj => requestObj.request))

const getAllPackagesFromJson = (jsonRes: PackageJson[]) =>
  jsonRes.map(pkgJson => getPkgDetails(pkgJson))

const useNpmApi = (
  initialPackages: Package[] = [],
  initialText = ''
): UseNpmApiHookReturn => {
  const [packages, setPackages] = useState<Package[]>(initialPackages)
  const [text, setText] = useState(initialText)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [percentage, setPercentage] = useState(0)
  const [requestObjs, setRequestObjs] = useState<RequestObj[]>([])

  useEffect(() => {
    const words = text !== '' ? getWords(text) : []

    setRequestObjs(oldRequestObjs => {
      const newRequestObjs = words.map(w => {
        const oldValue = oldRequestObjs.find(o => o.query === w)

        if (typeof oldValue !== 'undefined') {
          return oldValue
        }

        const abortController = new AbortController()

        return {
          query: w,
          request: fetchQuery(w, abortController.signal),
          abortController,
        }
      })

      oldRequestObjs.forEach(obj => {
        if (!words.includes(obj.query)) {
          obj.abortController.abort()
        }
      })

      return newRequestObjs
    })
  }, [text])

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)

      try {
        const jsonRes = await makeAllRequests(requestObjs)
        const pkgs = getAllPackagesFromJson(jsonRes)

        const matchingPackages = pkgs.filter(p => p.match)

        setPackages(pkgs)
        setPercentage(percent(matchingPackages.length, requestObjs.length))
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
          setIsError(true)
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [requestObjs])

  return [{ packages, isError, isLoading, percentage }, text, setText]
}

export default useNpmApi
