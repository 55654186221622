const choose = <T>(array: Array<T>): T =>
  array[Math.floor(Math.random() * array.length)]

const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout>

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor)
    })
}

const getWords = (text: string) => text.trim().split(/\s+/)

const percent = (partial: number, total: number): number => {
  // round to one decimal precision
  return Math.round((partial / total) * 1000) / 10
}

export { choose, debounce, getWords, percent }
