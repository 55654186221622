import React, { useEffect, ChangeEvent } from 'react'
import style from './App.module.css'
import { Loader, OutputText } from 'components'
import { useNpmApi } from 'hooks'
import { debounce } from 'utils'

const App = () => {
  const [
    { packages, isError, isLoading, percentage },
    query,
    setQuery,
  ] = useNpmApi()

  const debouncedSetQuery = debounce((value: string): void => {
    setQuery(value)
  }, 200)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    debouncedSetQuery(event.target.value)

  useEffect(() => {
    if (isLoading) {
      document.title = `NPMify - ...`
    } else if (query && percentage > 0) {
      document.title = `NPMify - Match: ${percentage}%`
    } else if (query && percentage === 0) {
      document.title = 'NPMify - ¯\\_(ツ)_/¯'
    } else {
      document.title = 'NPMify'
    }
  }, [percentage, query, isLoading])

  return (
    <div className={style.App}>
      <h1>NPMify</h1>
      <p>Type some text that you want to NPMify</p>
      <input
        autoFocus
        autoComplete="off"
        className={style.App_input}
        onChange={handleInputChange}
      />
      {isLoading && <Loader />}
      {!isLoading && query && (
        <OutputText packages={packages} percentage={percentage} />
      )}
      {isError && <p className={style.App_error}>Oops, an error occurred</p>}
    </div>
  )
}

export default App
